import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/inbqNzuePw0">
    <SEO title="Wrath - Seven Things" />
  </Layout>
)

export default SermonPost
